import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../utils/constants";

const useSearch = (searchQuery) => {
    const [searchLoading, setSearchLoading] = useState(true);
    const [searchResult, setSearchResult] = useState([]);
    useEffect(() => {
        let cancel;
        if (!searchQuery) {
            setSearchResult([]);
            setSearchLoading(false);
            return;
        }
        setSearchLoading(true);
        const config = {
            method: "GET",
            url: `${BASE_URL}/search-product?q=${searchQuery || "addd"}`,
            cancelToken: new axios.CancelToken((c) => (cancel = c)),
        };
        axios(config)
            .then((response) => {
                setSearchLoading(false);
                setSearchResult(response.data.data);
            })
            .catch((error) => {
                if (axios.isCancel(error)) return;
            });

        return () => {
            cancel();
        };
    }, [searchQuery]);
    return { searchLoading, searchResult };
};

export default useSearch;
