import axios from "axios";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../../components/ProductCard";
import { BASE_URL } from "../../utils/constants";
import Spinner from "../../components/Spinner";

import classes from "./categorydetail.module.css";

const CategoryDetail = () => {
    const { categoryId } = useParams();

    const [products, setProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(true);

    const getProductsHandler = useCallback(async () => {
        const config = {
            url: `${BASE_URL}/products?category=${categoryId}`,
        };
        try {
            const response = await axios(config);
            setProductsLoading(false);
            setProducts(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }, [categoryId]);

    useEffect(() => {
        getProductsHandler();
        return () => {};
    }, [getProductsHandler]);

    return (
        <div className="container">
            <div className={classes.wrapper}>
                <div className={classes.grid__products}>
                    {productsLoading && (
                        <div className={classes.grid__products_loader}>
                            <Spinner />
                        </div>
                    )}
                    {!productsLoading &&
                        products.length > 0 &&
                        products.map((product, index) => {
                            return (
                                <div
                                    className={classes.grid__product}
                                    key={index}
                                >
                                    <ProductCard product={product} />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default CategoryDetail;
