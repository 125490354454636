import React from "react";

import IconBadge from "../IconBadge";

import logo from "../../assets/logo.webp";
import ic_search from "../../assets/icons/search.svg";
import ic_menu from "../../assets/icons/menu.svg";

import classes from "./header.module.css";
import { useContext } from "react";
import LayoutContext from "../../contexts/layout_context";
import { useNavigate } from "react-router-dom";
import CartContext from "../../contexts/cart_context";

const Header = () => {
    const navigate = useNavigate();
    const ctx = useContext(LayoutContext);
    const cartContext = useContext(CartContext);

    const cartClickHandler = () => {
        navigate("/cart");
    };
    return (
        <div className={classes.header_wrapper}>
            <div className="container">
                <div className={classes.wrapper}>
                    <div className={classes.header_right}>
                        <img
                            className={classes.header_right__menu}
                            src={ic_menu}
                            alt="menu"
                        />
                    </div>
                    <div className={classes.header_logo}>
                        <img
                            className={classes.header_logo__logo}
                            src={logo}
                            alt="logo"
                            onClick={() => {
                                navigate("/");
                            }}
                        />
                    </div>
                    <div className={classes.header_left}>
                        <IconBadge
                            onClick={cartClickHandler}
                            count={cartContext.getCartItemsCount()}
                        />
                        <img
                            className={classes.header_left__search}
                            src={ic_search}
                            alt="search"
                            onClick={ctx.toggleSearch}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
