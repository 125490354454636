import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { CartContextProvider } from "./contexts/cart_context";
import { LayoutContextProvider } from "./contexts/layout_context";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <CartContextProvider>
                <LayoutContextProvider>
                    <App />
                </LayoutContextProvider>
            </CartContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);
