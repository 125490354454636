import { createContext, useState } from "react";

const initialState = {
    getCartTotal: () => {},
    addtoCartHandler: () => {},
    getCartHandler: () => {},
    getCartItemsCount: () => {},
    deleteCartItemHandler: () => {},
    getOrderItemsHandler: () => {},
    clearCartHandler: () => {},
    getCartItemHandler: () => {},
};

const CartContext = createContext(initialState);

export const CartContextProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    const getCartTotal = () => {
        return cartItems.reduce(
            (total, item) => item.price * item.qty + total,
            0
        );
    };
    const addtoCartHandler = (product, qty = 1) => {
        const itemIndex = cartItems.findIndex(
            (item) => item._id === product._id
        );
        if (itemIndex === -1) {
            setCartItems((prevState) => {
                return [
                    ...prevState,
                    {
                        _id: product._id,
                        name: product.name,
                        image: product.imagekeys[0],
                        price: product.retailprice,
                        qty: qty,
                    },
                ];
            });
        } else {
            const newCart = [...cartItems];
            const item = { ...newCart[itemIndex] };
            item.qty = qty;
            newCart[itemIndex] = item;
            setCartItems(newCart);
        }
    };
    const deleteCartItemHandler = (productId) => {
        setCartItems((prevState) => {
            return [...prevState.filter((item) => item._id !== productId)];
        });
    };
    const getCartItemsCount = () => {
        return cartItems.length;
    };
    const getCartHandler = () => {
        return cartItems;
    };
    const clearCartHandler = () => {
        setCartItems([]);
    };
    const getCartItemHandler = (productId) => {
        return cartItems.find((item) => item._id === productId);
    };
    const getOrderItemsHandler = () => {
        const orderItems = cartItems.map((item) => {
            return {
                pid: item._id,
                unit: "nos",
                qty: item.qty,
            };
        });
        return orderItems;
    };

    return (
        <CartContext.Provider
            value={{
                getCartTotal,
                addtoCartHandler,
                getCartHandler,
                getCartItemsCount,
                deleteCartItemHandler,
                getOrderItemsHandler,
                clearCartHandler,
                getCartItemHandler,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export default CartContext;
