import React from "react";
import { useState } from "react";
import { useContext } from "react";
import CartContext from "../../contexts/cart_context";
import classes from "./cartsummary.module.css";
import axios from "axios";
import LayoutContext from "../../contexts/layout_context";
import { useNavigate } from "react-router-dom";

const CartSummary = () => {
  const navigate = useNavigate();
  const cartContext = useContext(CartContext);
  const layoutContext = useContext(LayoutContext);
  const [orderProcessing, setOrderProcessing] = useState(false);
  const [customer, setCustomer] = useState({ name: "", mobileNumber: "" });
  const customerChangeHandler = (e) => {
    setCustomer((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  const placeOrderhandler = async () => {
    setOrderProcessing(true);
    const data = {
      items: cartContext.getOrderItemsHandler(),
      username: customer.name,
      phonenumber: customer.mobileNumber,
      ordertype: "b2c",
    };

    const config = {
      method: "post",
      url: `http://139.59.29.212:5000/api/orders`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    try {
      const response = await axios(config);
      setOrderProcessing(false);
      layoutContext.setShowNotification((prevState) => {
        return {
          ...prevState,
          visible: true,
          message: "Order placed successfully",
          type: "notification",
          status: "success",
        };
      });
      cartContext.clearCartHandler();
      console.log(response.data.data);
      navigate(`/order-detail/${response.data.data.ordernumber}`);
    } catch (error) {
      setOrderProcessing(false);
      console.log(error);
    }
  };
  const submitButtonDisabled = () => {
    if (
      customer.name.trim() === "" ||
      customer.mobileNumber.trim() === "" ||
      orderProcessing ||
      cartContext.getCartItemsCount() === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={classes.summary__wrapper}>
      <h4 className={classes.summary__title}>Order Summary</h4>
      <table className={classes.summary__info_table}>
        <tbody>
          <tr>
            <td>Total Items</td>
            <td>{cartContext.getCartItemsCount()}</td>
          </tr>
          <tr>
            <td>SubTotal</td>
            <td>AED {cartContext.getCartTotal()}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Total</td>
            <td>AED {cartContext.getCartTotal()}</td>
          </tr>
        </tbody>
      </table>
      <h4 className={classes.summary__form_title}>
        Add your details for generate your token
      </h4>
      <div className={classes.summary__form}>
        <label>Mobile Number</label>
        <div className={classes.summary__form_phone_no}>
          <p>+971</p>
          <input
            type='text'
            name='mobileNumber'
            value={customer.mobileNumber}
            onChange={customerChangeHandler}
          />
        </div>
        <label>Customer Name</label>
        <input
          type='text'
          name='name'
          value={customer.name}
          onChange={customerChangeHandler}
        />
        <button disabled={submitButtonDisabled()} onClick={placeOrderhandler}>
          Proceed and Genrate Your Token
        </button>
      </div>
    </div>
  );
};

export default CartSummary;
