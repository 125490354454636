import React from "react";
import ReactDOM from "react-dom";
import classes from "./custommodal.module.css";
import { useEffect } from "react";
import { useContext } from "react";
import LayoutContext from "../../contexts/layout_context";

const CustomModal = () => {
    const { showNotification, setShowNotification } = useContext(LayoutContext);
    useEffect(() => {
        let notificationTimeout;
        if (
            showNotification.type === "notification" &&
            showNotification.visible
        ) {
            notificationTimeout = setTimeout(() => {
                setShowNotification((prevState) => {
                    return { ...prevState, visible: false };
                });
            }, 2500);
        }
        return () => {
            clearTimeout(notificationTimeout);
        };
    }, [showNotification, setShowNotification]);

    return ReactDOM.createPortal(
        <div
            className={`${classes.modal_notification} ${
                showNotification.visible && classes.modal_notification__visible
            }`}
        >
            <h4>{showNotification.message}</h4>
        </div>,
        document.querySelector("#modal")
    );
};

export default CustomModal;
