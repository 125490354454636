import React, { createContext, useState, useEffect } from "react";

const initialState = {
    visibleSearch: false,
    toggleSearch: () => {},
    showNotification: false,
    setShowNotification: () => {},
};

const LayoutContext = createContext(initialState);

export const LayoutContextProvider = ({ children }) => {
    const [visibleSearch, setVisibleSearch] = useState(false);
    const [showNotification, setShowNotification] = useState({
        visible: false,
        message: "",
        status: "success",
        type: "notification",
    });

    const toggleSearch = () => {
        setVisibleSearch(!visibleSearch);
    };

    useEffect(() => {
        if (visibleSearch) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [visibleSearch]);

    return (
        <LayoutContext.Provider
            value={{
                visibleSearch,
                toggleSearch,
                showNotification,
                setShowNotification,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutContext;
