import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../contexts/cart_context";

import ic_delete from "../../assets/icons/delete.svg";
import classes from "./cartitem.module.css";
import LayoutContext from "../../contexts/layout_context";
import { IMAGE_URL } from "../../utils/constants";

const CartItem = ({ cartItem }) => {
    const cartContext = useContext(CartContext);
    const layoutContext = useContext(LayoutContext);
    const [qty, setQty] = useState(cartItem.qty || 1);

    const cartItemDeleteHandler = () => {
        cartContext.deleteCartItemHandler(cartItem._id);
        layoutContext.setShowNotification((prevState) => {
            return {
                ...prevState,
                visible: true,
                message: "Product removed from your cart",
                type: "notification",
                status: "success",
            };
        });
    };

    const qtyChangeHandler = (action = "+", value = 1) => {
        if (action === "+") {
            setQty((prevState) => {
                return prevState + 1;
            });
        } else if (action === "-") {
            setQty((prevState) => {
                return prevState === 1 ? prevState : prevState - 1;
            });
        } else {
            if (isNaN(parseFloat(value)) || parseFloat(value) < 1) {
                setQty(1);
            } else {
                setQty(parseFloat(value));
            }
        }
    };
    useEffect(() => {
        cartContext.addtoCartHandler({ _id: cartItem._id }, qty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qty]);
    return (
        <div className={classes.cart_item__wrapper}>
            <div className={classes.cart_item__product_image}>
                <img src={`${IMAGE_URL}/${cartItem.image}`} alt="" />
                <div
                    className={classes.cart_item__delete}
                    onClick={cartItemDeleteHandler}
                >
                    <img src={ic_delete} alt="delete" />
                </div>
            </div>
            <div className={classes.cart_item__information}>
                <p className={classes.cart_item__product_name}>
                    {cartItem.name}
                </p>
                <p className={classes.cart_item__product_price}>
                    AED {cartItem.price}
                </p>

                <div className={classes.cart_item__qty_actions}>
                    <button onClick={() => qtyChangeHandler("-")}>-</button>
                    <input
                        type="number"
                        value={qty}
                        onChange={(e) => {
                            qtyChangeHandler(null, e.target.value);
                        }}
                    />
                    <button onClick={() => qtyChangeHandler("+")}>+</button>
                </div>

                <p className={classes.cart_item__product_total}>
                    <span className={classes.cart_item__product_total_lbl}>
                        Total:
                    </span>
                    <span>{cartItem.price * cartItem.qty} AED</span>
                </p>
            </div>
        </div>
    );
};

export default CartItem;
